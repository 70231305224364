import { $themeConfig } from '@themeConfig'
import Cookies from "js-cookie";
import useJwt from "@/auth/jwt/useJwt";

let configurationsData = []


// Now you can use the `menuItems` array to render your dynamic menu.

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    recentlyViewedClients: Cookies.get("recentlyViewedClients") ? JSON.parse(Cookies.get("recentlyViewedClients")) : [],
    configurationsData:configurationsData
  },
  getters: {
    recentlyViewedClients(state) {
      return state.recentlyViewedClients
    },
    configurationsData(state){
      return state.configurationsData.menuItems
    }
  },
  mutations: {
    UPDATA_CONFIGURATION_DATA(state, val){
      state.configurationsData = val
    },
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_CLIENT_DETAILS(state, data) {
      let recentlyViewedClients = state.recentlyViewedClients;
      let clientIndex = recentlyViewedClients.findIndex((client) => {
        return data.slug === client.slug;
      })
      if (clientIndex > -1) {
        const oldIndex = clientIndex;
        const newIndex = 0;
        if (oldIndex != newIndex) {
          let temp = recentlyViewedClients.splice(oldIndex, 1);
          recentlyViewedClients.splice(newIndex, 0, temp[0]);
        }
        Cookies.set("recentlyViewedClients", JSON.stringify(recentlyViewedClients));
        return;
      }
      if (state.recentlyViewedClients.length === 5) {
        state.recentlyViewedClients.splice(4, 1);
      }
      state.recentlyViewedClients.unshift({
        title: data.name,
        icon: 'ZapIcon',
        client: true,
        to: `/client/${data.slug}`,
        id: data.id,
        slug: data.slug
      });

      Cookies.set("recentlyViewedClients", JSON.stringify(state.recentlyViewedClients));
    },
    UPDATE_CLIENT_DETAILS_ON_DELETE(state, data) {
      let recentlyViewedClients = state.recentlyViewedClients;
      let clientIndex = recentlyViewedClients.findIndex((client) => {
        return data.id === client.id;
      })
      if (clientIndex > -1) {
        state.recentlyViewedClients.splice(clientIndex, 1);
        Cookies.set("recentlyViewedClients", JSON.stringify(state.recentlyViewedClients));
      }
    }
  },
  actions: {},
}